@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@font-face {
  font-family: "Tungsten";
  src: local("Tungsten"), url("./assets/fonts/Tungsten.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-container {
  width: 100%;
  height: 60px;
  background-color: #533278;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-container .logo {
  width: calc(100% / 3);
}

.header-container .logo img {
  width: 155px;
  margin-left: 14px;
}

.header-container .caption {
  width: calc(100% / 3);
  font-family: Tungsten;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.header-container .username {
  width: calc(100% / 3);
  font-family: Tungsten;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.header-container .close {
  width: calc(100% / 3);
  text-align: right;
}

.header-container .close {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  color: #ffffff;
  margin-right: 14px;
}

.main-container {
  width: 100%;
  height: calc(100vh - 120px);
}

.main-input-section {
  width: 100%;
  height: 70px;
  background-color: #cdaccf;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Original brightness */
.normal {
  filter: brightness(1);
  /* Default brightness */
}

/* Decreased brightness */
.dim {
  filter: brightness(0.5);
  /* Reduce brightness by 50% */
}

/* Increased brightness */
.bright {
  filter: brightness(1.5);
  /* Increase brightness by 50% */
}


.disabled-input-section {
  width: 100%;
  height: 70px;
  background-color: #cdaccf;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
}

.disabled-input-section .tooltiptext {
  position: absolute;
  bottom: -75vh;
  padding: 10px;
  background-color: rgb(83, 50, 120);
  font-size: 14px;
  color: #fff;
  border-radius: 3px;
  z-index: 1;
}

.main-input-section .search-img,
.disabled-input-section .search-img {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background-color: #533278;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-input-section .search-img img,
.disabled-input-section .search-img img {
  width: 28px;
}

.dropDown-img {
  /* position: absolute; */
  top: 15px;
  left: 19px;
  width: 205px;
  height: 50px;
  background-color: #533278;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  padding: 0px 20px;
}

option {
  text-align: left !important;
}

.main-input {
  width: calc(100% - 250.8px);
  height: 38px;
  flex-grow: 0;
  padding: 0 0 0 210px;
  border-radius: 40px;
  border: solid 2px #533278;
  background-color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  outline: none;
}

.main-body {
  /* width: calc(100% - 30px); */
  /* height: calc(100vh - 220px); */
  padding: 15px;
  overflow-y: auto;
}

.no-nav {
  height: calc(100vh - 160px);
}

.footer-container {
  width: 100%;
  height: 60px;
  background-color: #8c827a;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.footer-container .terms-content {
  display: flex;
  margin-left: 15px;
}

.footer-container .terms-btn {
  width: 138px;
  height: 30px;
  flex-grow: 0;
  border-radius: 5px;
  border: solid 2px #eae8db;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #eae8db;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 20px;
  cursor: pointer;
  padding-right: 5px;
}

.footer-container .terms-btn .check-box {
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 2px solid #eae8db;
}

.footer-container .terms-btn .checked {
  background-color: #eae8db;
  border: none;
  width: 20px;
  text-align: center;
}

.footer-container .terms-btn .checked img {
  width: 18px;
  border-radius: 2px;
}

.footer-container .terms-info {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #e3e5e5;
  display: flex;
  align-items: center;
}

.dismissable-info {
  flex-grow: 0;
  padding: 11.8px 41px 17.1px 12px;
  border-radius: 5px;
  background-color: #8c827a;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #f2f2f2;
  position: relative;
}

.dismissable-info-summary {
  flex-grow: 0;
  padding: 11.8px 41px 17.1px 12px;
  border-radius: 5px;
  background-color: #f6f0ec;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: black;
  position: relative;
}

.dismissable-info-summary .dismiss {
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 18px;
  color: #f2f2f2;
}

.dismissable-info .dismiss {
  position: absolute;
  top: 11px;
  right: 11px;
  font-size: 18px;
  color: #f2f2f2;
}

.dismissable-info .close {
  cursor: pointer;
}

.no-result {
  width: 100%;
  height: calc(100vh - 360px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-top: 20px;
  text-align: center;
}

.answer-container {
  background-color: #eeeeee;
  margin-top: 10px;
  padding: 16px;
  border-radius: 10px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  position: relative;
}

.answer-container .arrow-img {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
}

.answer-container span {
  width: calc(100% - 25px);
  display: inline-block;
}

.answers-container .accordion__item {
  border-radius: 10px;
  background-color: #eeeeee;
}

.answers-container .accordion__button[aria-expanded="false"]>.answer-container .arrow-img {
  transform: rotate(180deg);
}

.answer-context {
  white-space: pre-wrap;
  outline: none;
  padding: 16px;
  padding-top: 0px;
  padding-bottom: 1px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.button-container {
  padding: 16px;
  padding-top: 0;
  display: flex;
  flex-direction: row;
}

.button-container #readMoreBtn {
  width: 130px;
  height: 40px;
  flex-grow: 0;
  border-radius: 9px;
  border: none;
  outline: none;
  background-color: #543379;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.terms-of-use-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.terms-of-use-modal.open {
  display: flex;
}

.modal-box {
  width: 60%;
  background-color: white;
}

.modal-header {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.15px;
  text-align: left;
  color: #5f2161;
  padding-left: 30px;
  padding-right: 16px;
}

.modal-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-close img {
  opacity: 0.54;
}

.modal-body {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #000;
  /* margin-top: 17px; */
  /* margin-bottom: 57px; */
  /* padding-left: 30px;
  padding-right: 30px; */
  /* overflow: auto;
  max-height: 350px; */
}

.modal-footer {
  background-color: rgba(223, 223, 223, 0.25);
  border-top: 1px solid #bdbdbd;
  padding: 20px 23px;
  text-align: right;
}

.modal-footer button {
  width: 150px;
  height: 40px;
  margin: 0.3px 0 0 19.6px;
  border-radius: 30px;
  border: solid 2px #41b5c2;
  background-color: #41b5c2;
  font-family: Tungsten;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.44px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.modal-footer .terms-info {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #757575;

}

.modal-loader-div {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.loader-div {
  width: 100%;
  height: calc(100vh - 360px);
  display: flex;
  align-items: center;
  justify-content: center;
}


.loader-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.loader {
  color: #533278;
  font-size: 18px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 5px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

.answers-item {
  flex: 1 0 calc(33.33% - 20px);
  /* Adjust for padding */
  /* padding: 20px; */
  box-sizing: border-box;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  text-align: center;
  margin: 2px;
}

.comparatortoolfooter {
  width: 180px;
  height: 50px;
  background-color: #533278;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Add box-shadow to the transition property */
  /* Add box-shadow to create a subtle shadow effect */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
}

.see-more {
  color: red;
}

.see-more:hover {
  text-decoration: underline;
  color: blue;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
}

.ComparitatorComponent {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.comparitorLeftSide {
  flex-wrap: wrap;
  display: flex;
  width: 70%;
}

.comparitorRightSide {
  width: 30%;
  display: flex;
  justify-content: end;

}

.multiselection {
  margin: 5px;
  border: 1px solid gray;
  border-radius: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.addMoreButton {
  margin: 10px;
  border-radius: 100px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
  padding: 10px;
  border: 1px solid gray;
  font-weight: bold;
  cursor: pointer;
}

.addMoreSelectionDropdown {
  position: absolute;
  z-index: 999;
  margin-top: 60px;
  width: 400px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Add box-shadow to the transition property */
  /* Add box-shadow to create a subtle shadow effect */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  /* Adjust values as needed */
  background-color: white;
}

.comparitorTitle {
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
}

.dropdownOptions {
  margin: 7px;
  color: black;
  cursor: pointer;
}

.dropdownSubOptions {
  margin: 7px;
  margin-left: 25px;
  color: black;
  cursor: pointer;
}

.dropdownOptions:hover {
  margin: 7px;
  color: black;
  cursor: pointer;
  background-color: lightgray;
}

.dropdownSubOptions:hover {
  margin: 7px;
  color: black;
  margin-left: 25px;
  cursor: pointer;
  background-color: lightgray;
}

/* .modalOptionsCloseIcon {
  padding: 10px;
  cursor: pointer;
} */

.modalOptionsCloseIcon {
  margin-left: auto;
  padding: 10px;
  /* Push the close icon to the right */
  cursor: pointer;
  opacity: 0;
  /* Initially hide the close icon */
  transition: opacity 0.3s ease;
  /* Add a smooth transition effect */
}

.multiselection:hover .modalOptionsCloseIcon {
  opacity: 1;
  /* Show the close icon on hover */
}

.multiselection:hover {
  background-color: #e3e5e5
}

.multi-dropDown-img {
  /* position: absolute; */
  top: 15px;
  left: 19px;
  width: 205px;
  height: 50px;
  background-color: #533278;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  padding: 0px 20px;
  border-radius: 20px;
}

.modal-search-container {
  height: 70px;
  display: flex;
  align-items: center;
}

.search-button {
  width: 150px;
  height: 40px;
  background-color: #533278;
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}

.modal-results-container {
  border: 3px solid gray;
  padding: 20px;
  border-radius: 10px;
}

.modal-content {
  min-height: 700px;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

mark {
  background-color: #81ffff;
}

@media screen and (max-width: 1024px) {
  .header-container .caption {
    font-size: 18px;
  }

  .header-container .username {
    font-size: 18px;
  }

}

@media screen and (max-width: 700px) {
  .header-container .logo {
    width: 105px;
  }

  .header-container .logo img {
    width: 100px;
  }

  .header-container .caption {
    width: calc(100% - 140px);
    font-size: 15px;
  }

  .header-container .username {
    width: calc(100% - 140px);
    font-size: 15px;
  }

  .header-container .close {
    width: auto;
  }

  .header-container .close img {
    width: 18px;
  }

  .modal-box {
    width: 85%;
  }

  .modal-header {
    font-size: 17px;
  }

  .modal-close {
    width: 35px;
    height: 35px;
  }

  .modal-body {
    font-size: 15px;
    margin-bottom: 27px;
  }

  .modal-footer button {
    font-size: 15px;
    width: 100px;
    height: 35px;
    margin-left: 5px;
    justify-content: space-between;
  }

  .footer-container .terms-btn {
    width: 105px;
    font-size: 13px;
    margin-right: 10px;
    padding-right: 5px;
  }

  .footer-container .terms-info {
    font-size: 13px;
  }

  .footer-container .terms-content {
    margin-left: 10px;
  }

  .modal-footer .terms-info {
    font-size: 14px;
  }

  .answers-item {
    flex-basis: calc(100% - 20px);
  }
}